import React from 'react';
import styled from 'styled-components';
import MediaLayout from 'layouts/media-layout';
import SEO from 'components/seo';
import { GoBackButton, MobileHeading } from 'components/misc';

const Presentations = ()=> {
  return (
    <MediaLayout>
      <SEO title="Media" />
      <GoBackButton path="media" name="Media" />
      <MobileHeading heading="Prezentacje" />
      Prezentacje
    </MediaLayout>
  );
};

export default Presentations;
